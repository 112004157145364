<template>
  <div class="syncRuleList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>原表名：</span>
        <a-input
          placeholder="请输入原表名"
          v-model.trim="sourceTableName"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchConditionItem">
        <span>原数据库名：</span>
        <a-input
          placeholder="请输入原数据库名"
          v-model.trim="sourceDatabaseName"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchConditionItem">
        <span>下游表名：</span>
        <a-input
          placeholder="请输入下游表名"
          v-model.trim="sinkTableName"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="middle"
      :rowKey="(record) => record.id"
      :scroll="{ x: true }"
    >
      <span slot="sourceDatabaseName" slot-scope="text, record">
        {{ text }}.{{ record.sourceTableName }}
      </span>
      <span slot="sinkDatabaseName" slot-scope="text, record">
        {{ text }}.{{ record.sinkTableName }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <convert-rule-modal
      :title="title"
      :show="show"
      :ruleId="ruleId"
      type="template"
      @close="close"
      @submit="submit"
    ></convert-rule-modal>
  </div>
</template>
<script>
import * as api from "../lib/syncRuleList.js";
import convertRuleModal from "./convertRuleModal.vue";

export default {
  name: "syncRuleList",
  components: { convertRuleModal },
  data() {
    return {
      sourceTableName: "",
      sourceDatabaseName: "",
      sinkTableName: "",
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "原数据库名",
          dataIndex: "sourceDatabaseName",
          scopedSlots: { customRender: "sourceDatabaseName" },
        },
        {
          title: "下游数据库名",
          dataIndex: "sinkDatabaseName",
          scopedSlots: { customRender: "sinkDatabaseName" },
        },
        {
          title: "同步类型",
          dataIndex: "syncType",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      title: "",
      show: false,
      ruleId: 0,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        sourceTableName: this.sourceTableName,
        sourceDatabaseName: this.sourceDatabaseName,
        sinkTableName: this.sinkTableName,
      };
      this.loadingTable = true;
      api
        .syncRulePage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.ruleId = val.id;
      this.show = true;
    },
    // 新增
    add() {
      this.title = "新增";
      this.ruleId = undefined;
      this.show = true;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    close() {
      this.show = false;
    },
    submit() {
      this.show = false;
      this.query();
    },
  },
};
</script>
